import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { ArticleCard } from "../components/Article/ArticleCard"
import { CurveCarveTop, CurveBulgeBottom } from "../components/Curves/Curve"
import { Newsletter } from "../components/Misc/Newsletter"
import { SideMenuNews } from "../components/Navigation/SidemenuNews"

const News = ({ data, pageContext }) => {
  const {
    allContentfulArticle: { nodes: articles },
  } = data

  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1
      ? "/property-news-uk/"
      : `/property-news-uk/${(currentPage - 1).toString()}`
  const nextPage = `/property-news-uk/${(currentPage + 1).toString()}`

  return (
    <Layout>
      <SEO
        title={`Property News UK – targeted news for property investors (Page ${currentPage})`}
        description={`Property investor news (Page ${currentPage}): helping investors discern the most
              profitable property sectors based on breaking property news UK,
              current events and government policy changes.`}
      />
      <Container>
        <Row>
          <Col>
            <h1>Property Investors News</h1>
            <p className="lead">
              Property investor news: helping investors discern the most
              profitable property sectors based on breaking property news UK,
              current events and government policy changes.
            </p>
          </Col>
        </Row>
      </Container>
      <CurveCarveTop variant="light" />
      <div className="bg-light  py-5">
        <Container>
          <Row>
            <Col lg={8}>
              <Row>
                {articles.map(article => {
                  return (
                    <Col sm={6} key={article.id}>
                      <ArticleCard article={article} />
                    </Col>
                  )
                })}
              </Row>
              <Row className="mb-5">
                <Col>
                  {!isFirst && (
                    <Link to={prevPage} rel="prev">
                      ← Previous Page
                    </Link>
                  )}
                </Col>
                {/* <Col>
                  {Array.from({ length: numPages }, (_, i) => (
                    <Link
                      key={`pagination-number${i + 1}`}
                      to={`/${i === 0 ? "" : i + 1}`}
                    >
                      {i + 1}
                    </Link>
                  ))}
                </Col> */}
                <Col className="text-right">
                  {!isLast && (
                    <Link to={nextPage} rel="next">
                      Next Page →
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              <SideMenuNews />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveBulgeBottom variant="light" />
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query NewsPageListQuery($skip: Int!, $limit: Int!) {
    allContentfulArticle(
      sort: { fields: [isFeatured, createdAt], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...ArticleListFragment
      }
    }
  }
`

export default News
